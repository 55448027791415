@import url('https://fonts.googleapis.com/css2?family=Livvic:wght@400;500;700&display=swap');

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #418db8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #196591;
}

#chat-container::-webkit-scrollbar {
  display: none !important;
}

body {
  font-family: 'Inter', sans-serif;
}

h2 {
  font-family: 'Inter', sans-serif !important;
}
